import React from 'react'
import Helmet from 'react-helmet'
import { navigate } from '@reach/router'
import MissingInfoForm from '../components/MissingInfo'
import Box from '../components/Box'
import Header from '../components/Header'
import * as routes from '../constants/routes'
import { loggedIn } from '../services/moocfi'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: calc(100vh - 159px);
`

const MissingInfoPage = props => {
  const { intl } = props

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'globals.courseOverview' }),
      path: '/hr',
    },
    {
      title: intl.formatMessage({ id: 'missingInfo.title' }),
      path: '/hr/missinginfo',
    },
  ]

  if (!loggedIn()) {
    navigate(intl.formatMessage({ id: 'routes.SIGN_IN' }))
    return null
  }

  return (
    <BackgroundBox>
      <Helmet title={intl.formatMessage({ id: 'missingInfo.title' })} />
      <Header breadcrumbs={breadcrumbs} />
      <MissingInfoForm langKey="hr" location={props.location} redirect={intl.messages["routes.LANDING"]} />
    </BackgroundBox>
  )
}

export default injectIntl(MissingInfoPage)
